import { httpFetchTransactions } from "api/support/transaction";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import SupportSidebar from "components/layout/SupportSidebar";
import Filter from "components/ui/Filter";
import Pagination from "components/ui/Pagination";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import Toggle from "components/ui/Toggle";
import { Itransaction } from "components/ui/Transaction";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { supportRoutes } from "routes/routes";
import currency, { cryptoFormat, moneyFormat } from "utilities/currency";
import { capitalize } from "utilities/misc";
import { cryptos } from "utilities/wallets";
import TransactionInfo from "./TransactionInfo";

const types = ["naira", "market", "send-receive", "swap", "recurring"];

const typesToggle: any = {
  naira: [
    "Deposit Naira",
    "Send Money",
    "Earned Naira",
    "Refund",
    "Pay Bills",
    "Reverse Deposits",
  ],
  market: ["Buy", "Sell"],
  "send-receive": ["Send", "Receive", "Send Money", "Refund", "Pay Bills"],
  swap: ["Swap"],
  recurring: ["Active plans", "Paused plans", "Cancelled plans", "Purchases"],
};

const typesTitle: any = {
  naira: "Naira",
  market: "Buy & Sell",
  "send-receive": "Send & Receive",
  swap: "Swap",
  recurring: "Recurring Buy",
};

const transactionTypes: any = {
  "Deposit Naira": "deposit",
  "Send Money": "withdrawal",
  "Pay Bills": "bill",
  Refund: "refund",
  "Earned Naira": "earning",
  Buy: "buy",
  Sell: "sell",
  Send: "send",
  Receive: "receive",
  Swap: "swap",
  "Active plans": "active",
  "Paused plans": "paused",
  "Cancelled plans": "stopped",
  Purchases: "purchases",
  "Reverse Deposits": "reverse-deposits",
};

const statuses = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Completed",
    value: "successful",
  },
  {
    label: "Failed",
    value: "cancelled",
  },
];

const cryptoFilters = [
  {
    label: "All",
    value: "all",
  },
  ...cryptos.map((crypto) => ({
    label: crypto.toUpperCase(),
    value: crypto,
  })),
];

const relatedToCrypto = ["market", "send-receive", "swap"];

const Transactions: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("all");
  const [transaction, setTransaction] = useState<Itransaction | null>(null);
  const [filter, setFilter] = useState("external");
  const [cryptoFilter, setCryptoFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 50;
  const navigate = useNavigate();
  const params = useParams();
  const type = params.type as string;

  useEffect(() => {
    if (!types.includes(type)) {
      navigate(supportRoutes.dashboard);
    }
    setValue(typesToggle[type][0]);
  }, [type, navigate]);

  const [value, setValue] = useState(typesToggle[type][0]);

  useEffect(() => {
    setTransaction(null);
  }, [value]);

  const cryptoType = type === "naira" ? "naira" : "crypto";

  const {
    data: { transactions, total, page },
    refetch,
  } = useQuery(
    [
      "transactions",
      cryptoType,
      value,
      filter,
      cryptoFilter,
      status,
      currentPage,
    ],
    () =>
      httpFetchTransactions(
        cryptoType,
        transactionTypes[value],
        filter,
        relatedToCrypto.includes(type) ? cryptoFilter : undefined,
        limit,
        currentPage,
        status
      ),
    {
      initialData: {
        transactions: [],
      },
    }
  );

  const handleClick = useCallback(
    (tr: Itransaction) => {
      if (transaction?.id === tr.id) {
        setTransaction(null);
        setIsOpen(false);
      } else {
        setTransaction(tr);
        setIsOpen(true);
      }
    },
    [transaction]
  );

  const transactionAmount = useCallback(
    (tr: Itransaction) =>
      tr?.currencyType === "naira" ||
      ["bill", "withdrawal", "refund"].includes(tr?.transactionType)
        ? `${currency("ngn")} ${moneyFormat(tr?.amount)}`
        : `${cryptoFormat(tr?.amount || 0)} ${tr?.crypto?.toUpperCase() || ""}`,
    []
  );

  const firstTitle = useMemo(() => {
    if (type === "send-receive" || type === "swap") {
      return "Type";
    } else {
      return "User";
    }
  }, [type]);

  const firstItem = useCallback(
    (transaction: Itransaction) => {
      if (value === "Send") {
        return `Sent ${transaction.crypto?.toUpperCase()}`;
      } else if (value === "Receive") {
        return `Received ${transaction.crypto?.toUpperCase()}`;
      } else if (value === "Swap") {
        return `${transaction.fromCrypto?.toUpperCase()} - ${transaction.crypto?.toUpperCase()}`;
      } else if (
        transaction.transactionType === "withdrawal" &&
        transaction.place === "external"
      ) {
        return (
          <span className="px-1 text-xs bg-orange-300 rounded">
            sell.bitoshi.africa
          </span>
        );
      }
      return `@${transaction?.user?.userName}`;
    },
    [value]
  );

  const secondTitle = useMemo(() => {
    if (
      value === "Deposit Naira" ||
      value === "Withdraw Naira" ||
      value === "Earned Naira"
    ) {
      return value.split(" ")[0] + " amount";
    } else if (value === "Buy" || value === "Sell") {
      return "Amount";
    } else if (value === "Send" || value === "Receive" || value === "Swap") {
      return "User";
    } else {
      return "Asset";
    }
  }, [value]);

  const secondItem = useCallback(
    (transaction: Itransaction) => {
      if (value === "Send" || value === "Receive" || value === "Swap") {
        return `@${transaction?.user?.userName}`;
      } else if (type === "recurring") {
        return transaction.crypto?.toUpperCase();
      }
      return `${transactionAmount(transaction)}`;
    },
    [value, type, transactionAmount]
  );

  const thirdTitle = useMemo(() => {
    if (type === "recurring" && value !== "Purchases") return "Frequency";
    else if (type === "recurring" && value === "Purchases") return "Amount";
    return "Date & Time";
  }, [type, value]);

  const thirdItem = useCallback(
    (transaction: Itransaction) => {
      if (type === "recurring") {
        if (value === "Purchases") {
          return `${currency("ngn")} ${transaction.amount}`;
        }
        return capitalize(transaction.frequency);
      }
      return `${DateTime.fromISO(transaction.timestamp).toFormat(
        "DD"
      )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`;
    },
    [type, value]
  );

  const filters = useMemo(() => {
    if (value === "Buy") {
      return [
        {
          label: "Buy to external wallet",
          value: "external",
        },
        {
          label: "Buy to Bitoshi wallet",
          value: "bitoshi",
        },
      ];
    } else if (value === "Sell") {
      return [
        {
          label: "Sell from external wallet",
          value: "external",
        },
        {
          label: "Sell from Bitoshi wallet",
          value: "bitoshi",
        },
      ];
    } else if (value === "Send" || value === "Receive") {
      return [
        {
          label: "External transfer",
          value: "external",
        },
        {
          label: "Internal transfer",
          value: "bitoshi",
        },
      ];
    } else if (value === "Swap") {
      return [
        {
          label: "Bitoshi Internal Swap",
          value: "bitoshi",
        },
        {
          label: "Bitoshi - External Swap",
          value: "bitoshi-external",
        },
        {
          label: "External - Bitoshi Swap",
          value: "external-bitoshi",
        },
        {
          label: "External Wallet Swap",
          value: "external",
        },
        {
          label: "Pending Swap",
          value: "pending",
        },
      ];
    }

    return [
      {
        label: "Any",
        value: "undefined",
      },
    ];
  }, [value]);

  useEffect(() => {
    if (filters.length) {
      setFilter(filters[0].value);
    }
    setStatus("all");
    setCryptoFilter("all");
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, filters]);

  return (
    <Container>
      <SupportSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-2xl">{typesTitle[type]} Transactions</h3>
          <div className="w-3/6">
            <Search type="transaction" />
          </div>
        </div>
        <div className="my-8">
          {type !== "swap" && (
            <Toggle
              data={typesToggle[type]}
              value={value}
              setValue={setValue}
            />
          )}
        </div>

        <div className="flex items-center mt-5 gap-x-5">
          <div>
            {relatedToCrypto.includes(type) && (
              <div>
                <span className="text-sm">Filter by:</span>
                <Filter data={filters} setValue={setFilter} value={filter} />
              </div>
            )}
          </div>

          <div>
            {relatedToCrypto.includes(type) && (
              <div>
                <span className="text-sm">Crypto:</span>
                <Filter
                  data={cryptoFilters}
                  setValue={setCryptoFilter}
                  value={cryptoFilter}
                />
              </div>
            )}
          </div>

          <div>
            <span className="text-sm">Status:</span>
            <Filter data={statuses} setValue={setStatus} value={status} />
          </div>
        </div>

        <div className="flex gap-8 mt-14">
          <div className="w-7/12">
            <Table
              header={[firstTitle, secondTitle, thirdTitle]}
              data={[
                ...(transactions || []).map((transaction: Itransaction) => [
                  firstItem(transaction),
                  secondItem(transaction),
                  thirdItem(transaction),
                  () => handleClick(transaction),
                ]),
              ]}
            />
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              limit={limit}
              page={page}
              total={total}
            />
          </div>
          {isOpen && (
            <TransactionInfo transaction={transaction} refetch={refetch} />
          )}
        </div>
      </Main>
    </Container>
  );
};
export default Transactions;
