import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Spinner from "components/ui/Spinner";
import VButton from "components/ui/VButton";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQueries } from "react-query";
import { cryptoFormat } from "utilities/currency";
import { showDanger, showSuccess } from "utilities/misc";
import { get, post } from "utilities/network";

const coins = [
  { name: "USDT (TRC20)", key: "usdt" },
  { name: "USDT (ERC20)", key: "usdtETH" },
  { name: "USDT (BEP20)", key: "usdtBNB" },
  { name: "TRX", key: "trx" },
  { name: "ETH", key: "eth" },
  { name: "USDC", key: "usdc" },
  { name: "BNB", key: "bnb" },
  { name: "LTC", key: "ltc" },
];

const BtcBalance: React.FC = () => {
  const coinQueries = useQueries(
    coins.map((coin) => ({
      queryKey: ["coinBalance", coin.key],
      queryFn: () => get(`admin/analytics/coinBalance?which=${coin.key}`),
    }))
  );

  const [sell, setSell] = useState({
    btc: 0,
    usd: 0,
    blockchainTotal: 0,
    blockchainTotalUsd: 0,
  });
  const [receive, setReceive] = useState({
    btc: 0,
    usd: 0,
    blockchainTotal: 0,
    blockchainTotalUsd: 0,
  });
  const [web, setWeb] = useState({
    btc: 0,
    usd: 0,
    blockchainTotal: 0,
    blockchainTotalUsd: 0,
  });
  const [usdtBalances, setUsdtBalances] = useState<any>({
    balance: 0,
    lastUpdated: DateTime.now().toISO(),
    btc: {},
  });
  const [isLoading, setLoading] = useState(false);

  /// Bump BTC Fee
  const [hash, setHash] = useState("");
  const [fee, setFee] = useState("");
  const [bumpLoading, setBumpLoading] = useState(false);

  const handleBump = async () => {
    if (!hash || !fee) return showDanger("Enter valid hash and fee");
    setBumpLoading(true);
    try {
      let result = await post(`admin/transactions/bumpBtcFee`, {
        hash,
        fee,
      });
      if (result?.data?.success) {
        showSuccess(result?.data?.message || "Hash bumped successfully");
        setHash("");
        setFee("");
      }
      setBumpLoading(false);
    } catch (e) {
      console.log(e);
      setBumpLoading(false);
      showDanger("Something went wrong");
    }
  };

  const handleFetch = async (type: string) => {
    setLoading(true);
    try {
      let result = await get(`admin/analytics/btcBalance?which=${type}`);
      setLoading(false);
      if (type === "sell") {
        setSell(result);
      } else if (type === "receive") {
        setReceive(result);
      } else if (type === "usdtBalances") {
        setUsdtBalances(result);
      } else {
        setWeb(result);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      showDanger("Something went wrong");
    }
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <section className="flex justify-between-center">
          <div>
            <h2 className="text-xl">Bitcoin Balance</h2>
            <div className="flex gap-8 mt-14">
              <div className="flex">
                <div className="gap-5">
                  <div className="relative mr-5">
                    <label>Sell Balance</label>
                    <h3 className="mt-2">
                      BTC:{" "}
                      <span className="text-xl">
                        {sell.btc || usdtBalances?.btc?.sell?.btc || 0}
                      </span>
                    </h3>
                    <h3 className="mt-2">
                      USD:{" "}
                      <span className="text-xl">
                        {sell.usd || usdtBalances?.btc?.sell?.usd || 0}
                      </span>
                    </h3>

                    <h3 className="pt-2 mt-2">
                      Onchain BTC:{" "}
                      <span className="text-xl">
                        {sell.blockchainTotal ||
                          usdtBalances?.btc?.sell?.blockchainTotal ||
                          0}
                      </span>
                    </h3>
                    <h3 className="mt-2">
                      Onchain USD:{" "}
                      <span className="text-xl">
                        {sell.blockchainTotalUsd ||
                          usdtBalances?.btc?.sell?.blockchainTotalUsd ||
                          0}
                      </span>
                    </h3>
                    <h3 className="mt-2">
                      Last Updated :{" "}
                      <span className="text-lg">
                        {usdtBalances?.btc?.sell?.lastUpdated ? (
                          <span>
                            {DateTime.fromISO(
                              usdtBalances?.btc?.sell?.lastUpdated
                            ).toFormat("DD")}
                            &nbsp;
                            {DateTime.fromISO(
                              usdtBalances?.btc?.sell?.lastUpdated
                            ).toFormat("t")}
                          </span>
                        ) : null}
                      </span>
                    </h3>
                  </div>

                  <VButton
                    status={isLoading ? "pending" : ""}
                    onClick={() => handleFetch("sell")}
                    className="w-5/12 px-20 py-3 mt-5 font-light text-white bg-black rounded-lg"
                  >
                    Refetch
                  </VButton>
                </div>

                <div className="gap-5 mr-5">
                  <div className="relative">
                    <label>Receive Balance</label>

                    <h3 className="mt-2">
                      BTC:{" "}
                      <span className="text-xl">
                        {receive.btc || usdtBalances?.btc?.receive?.btc || 0}
                      </span>
                    </h3>
                    <h3 className="mt-2">
                      USD:{" "}
                      <span className="text-xl">
                        {receive.usd || usdtBalances?.btc?.receive?.usd || 0}
                      </span>
                    </h3>

                    <h3 className="pt-2 mt-2">
                      Onchain BTC:{" "}
                      <span className="text-xl">
                        {receive.blockchainTotal ||
                          usdtBalances?.btc?.receive?.blockchainTotal ||
                          0}
                      </span>
                    </h3>
                    <h3 className="mt-2">
                      Onchain USD:{" "}
                      <span className="text-xl">
                        {receive.blockchainTotalUsd ||
                          usdtBalances?.btc?.receive?.blockchainTotalUsd ||
                          0}
                      </span>
                    </h3>
                    <h3 className="mt-2">
                      Last Updated :{" "}
                      <span className="text-lg">
                        {usdtBalances?.btc?.receive?.lastUpdated ? (
                          <span>
                            {DateTime.fromISO(
                              usdtBalances?.btc?.receive?.lastUpdated
                            ).toFormat("DD")}
                            &nbsp;
                            {DateTime.fromISO(
                              usdtBalances?.btc?.receive?.lastUpdated
                            ).toFormat("t")}
                          </span>
                        ) : null}
                      </span>
                    </h3>
                  </div>

                  <VButton
                    status={isLoading ? "pending" : ""}
                    onClick={() => handleFetch("receive")}
                    className="w-5/12 px-20 py-3 mt-5 font-light text-white bg-black rounded-lg"
                  >
                    Refetch
                  </VButton>
                </div>

                <div className="gap-5">
                  <div className="relative">
                    <label>Web Balance</label>

                    <h3 className="mt-2">
                      BTC: <span className="text-xl">{web.btc}</span>
                    </h3>
                    <h3 className="mt-2">
                      USD: <span className="text-xl">{web.usd}</span>
                    </h3>

                    <h3 className="pt-2 mt-2">
                      Onchain BTC:{" "}
                      <span className="text-xl">{web.blockchainTotal}</span>
                    </h3>
                    <h3 className="mt-2">
                      Onchain USD:{" "}
                      <span className="text-xl">{web.blockchainTotalUsd}</span>
                    </h3>
                  </div>

                  <VButton
                    status={isLoading ? "pending" : ""}
                    onClick={() => handleFetch("web")}
                    className="w-5/12 px-20 py-3 mt-5 font-light text-white bg-black rounded-lg"
                  >
                    Refetch
                  </VButton>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 className="mb-3 text-xl">Bump BTC Fee</h2>
            <div className="flex gap-5">
              <div className="relative w-6/12">
                <label>Hash</label>
                <input
                  type="text"
                  name="hash"
                  value={hash}
                  onChange={(e) => setHash(e.target.value)}
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                />
              </div>
              <div className="relative w-3/12">
                <label>Fee</label>
                <input
                  type="number"
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                  name="fee"
                  value={fee}
                  onChange={(e) => setFee(e.target.value)}
                />
              </div>
              <div className="w-3/12 text-center">
                <VButton
                  status={bumpLoading ? "pending" : ""}
                  onClick={handleBump}
                  className="px-20 py-3 mt-8 font-light text-white bg-black rounded-lg"
                >
                  Bump
                </VButton>
              </div>
            </div>
          </div>
        </section>

        <section className="grid grid-cols-2 mt-20 gap-14 md:grid-cols-4">
          {coins.map((coin, index) => (
            <div key={coin.key}>
              <h2 className="text-xl">{coin.name} Address Balance</h2>
              <div
                className={`py-5 mt-2 rounded-lg px-7 ${
                  index % 2 === 0
                    ? "text-white bg-black"
                    : "text-black bg-white border-2 border-black"
                }`}
              >
                {coinQueries[index].isLoading ? (
                  <div className="flex items-center justify-center">
                    <Spinner className="text-lg" />
                  </div>
                ) : (
                  <>
                    <h4 className="mb-2 text-lg font-normal">
                      Balance:&nbsp;
                      {cryptoFormat(coinQueries[index]?.data?.balance || 0)}
                    </h4>
                    <h5 className="mb-2 text-base font-normal">
                      USD Value:&nbsp;
                      {cryptoFormat(coinQueries[index]?.data?.usd || 0)}
                    </h5>
                    <p className="text-sm font-light">
                      <span>Last updated:</span>&nbsp;
                      <span className="font-thin">
                        {DateTime.fromISO(
                          coinQueries[index]?.data?.lastUpdated
                        ).toFormat("DD")}
                        &nbsp;
                        {DateTime.fromISO(
                          coinQueries[index]?.data?.lastUpdated
                        ).toFormat("t a")}
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
          ))}
        </section>
      </Main>
    </Container>
  );
};
export default BtcBalance;
