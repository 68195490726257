import { httpFetchPaymentMerchants } from "api/admin/stats";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import { Select } from "components/misc";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Toggle, { ToggleProps } from "react-toggle";
import "react-toggle/style.css";
import { showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const PaymentMerchant: React.FC = () => {
  const [activeMerchants, setActiveMerchants] = useState<any>([]);

  const { data, refetch } = useQuery(
    "paymentMerchants",
    httpFetchPaymentMerchants,
    {
      initialData: {
        merchants: [],
        paymentMerchants: [],
        nairaWithdrawal: true,
        billsPayment: true,
        billsProviders: [],
        currentBillProvider: "",
      },
      onSuccess: (data) => {
        setActiveMerchants(data.paymentMerchants);
      },
    }
  );

  const {
    execute: updateMerchant,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/transactions/setPaymentMerchant`, {
        paymentMerchant: activeMerchants,
      }),
    false
  );

  const handleSubmit = () => {
    updateMerchant();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "Updated successfully");
    }
  }, [value]);

  const handleChange = (e: any, index: number) => {
    const newMerchants = [...activeMerchants];
    const { value, name } = e.target;
    newMerchants[index][name] =
      name === "merchant" ? getMerchantByName(value)?.key : Number(value);
    setActiveMerchants(newMerchants);
  };

  const getMerchantByKey = (key: string) =>
    data?.merchants.find((a: any) => key === a.key);
  const getMerchantByName = (name: string) =>
    data?.merchants.find((a: any) => name === a.name);

  const handleToggleWithdrawal = (
    props: ToggleProps & { target: { checked: boolean } }
  ) => {
    post(`admin/transactions/setNairaWithdrawal`, {
      nairaWithdrawal: props.target.checked,
    })
      .then((res) => {
        if (res?.data?.success) {
          showSuccess(res?.data?.message || "Updated successfully");
        }
        refetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleToggleBillsPayment = (
    props: ToggleProps & { target: { checked: boolean } }
  ) => {
    post(`admin/transactions/setBillsPayment`, {
      billsPayment: props.target.checked,
    })
      .then((res) => {
        if (res?.data?.success) {
          showSuccess(res?.data?.message || "Updated successfully");
        }
        refetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeBillProvider = (e: any) => {
    const provider = (e.target.value || "")?.toLowerCase();
    post(`admin/transactions/setBillsPaymentProvider`, {
      provider,
    })
      .then((res) => {
        if (res?.data?.success) {
          showSuccess(res?.data?.message || "Updated successfully");
        }
        refetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="mb-10">
          <h2 className="text-xl">Payment Merchant</h2>
          <div className="mt-6">
            <label className="flex items-center gap-x-4">
              <Toggle
                checked={data?.nairaWithdrawal}
                onChange={handleToggleWithdrawal}
              />
              <span>Enable Withdrawal</span>
            </label>
          </div>
          <div className="flex gap-8 mt-10">
            <div className="flex">
              <div className="flex flex-col gap-4">
                {data?.paymentMerchants?.map((a: any, index: number) => (
                  <div
                    key={`${a?.merchant}-${index}`}
                    className="relative flex items-center gap-x-5"
                  >
                    <div>
                      <label>Merchant {index + 1}</label>
                      <Select
                        name="merchant"
                        onChange={(e) => handleChange(e, index)}
                        data={data?.merchants.map((p: any) => p.name)}
                        value={
                          getMerchantByKey(activeMerchants[index]?.merchant)
                            ?.name
                        }
                      />
                    </div>
                    <span> {"=>"} </span>
                    <div className="flex items-center mb-5 gap-x-3">
                      <div>
                        <label className="mb-2">Min. Amount</label>
                        <input
                          type="number"
                          name="min"
                          value={activeMerchants[index]?.min}
                          onChange={(e) => handleChange(e, index)}
                          className="w-full py-2 border-gray-300 rounded-lg"
                        />
                      </div>
                      <div>
                        <label className="mb-2">Max. Amount</label>
                        <input
                          type="number"
                          name="max"
                          value={activeMerchants[index]?.max}
                          onChange={(e) => handleChange(e, index)}
                          className="w-full py-2 border-gray-300 rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <VButton
                  status={status}
                  onClick={handleSubmit}
                  className="w-5/12 px-20 py-3 font-light text-white bg-black rounded-lg"
                >
                  Save
                </VButton>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-xl">Bill Payment Provider</h2>
          <div className="mt-6">
            <label className="flex items-center gap-x-4">
              <Toggle
                checked={data?.billsPayment}
                onChange={handleToggleBillsPayment}
              />
              <span>Enable Bill Purchase</span>
            </label>
          </div>
          <div className="flex gap-8 mt-10">
            <div className="flex">
              <div className="flex flex-col gap-4">
                <div>
                  <label>Provider </label>
                  <Select
                    className="capitalize"
                    name="billProvider"
                    onChange={(e) => handleChangeBillProvider(e)}
                    data={data?.billsProviders}
                    value={data?.currentBillProvider}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default PaymentMerchant;
