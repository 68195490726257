import { httpFetchVouchers } from "api/admin/transaction";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Pagination from "components/ui/Pagination";
import Table from "components/ui/Table";
import Toggle from "components/ui/Toggle";
import TransactionItem from "components/ui/TransactionItem";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import currency, { moneyFormat } from "utilities/currency";

const filters = ["All", "Redeemed", "Not Redeemed"];

const mapValueToFilter: { [key: string]: string } = {
  All: "all",
  Redeemed: "redeemed",
  "Not Redeemed": "not-redeemed",
};

const Vouchers: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState<(typeof filters)[number]>("All");
  const [voucher, setVoucher] = useState<any>(null);
  const limit = 50;
  const {
    data: { vouchers, total, page },
  } = useQuery(
    ["vouchers", value, currentPage],
    () => httpFetchVouchers(mapValueToFilter[value], limit, currentPage),
    {
      initialData: {
        vouchers: [],
      },
    }
  );

  const handleClick = (tr: any) => {
    if (voucher?.id === tr.id) {
      setVoucher(null);
      setIsOpen(false);
    } else {
      setVoucher(tr);
      setIsOpen(true);
    }
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-2xl">Vouchers</h3>
        </div>
        <div className="my-10">
          <Toggle data={filters} value={value} setValue={setValue} />
        </div>
        <div className="flex gap-8 mt-12">
          <div className="w-7/12">
            <Table
              header={["Username", "Amount", "Status", "Date"]}
              data={[
                ...(vouchers || []).map((voucher: any) => [
                  `@${voucher?.user?.userName}`,
                  `${currency("ngn")} ${moneyFormat(
                    Number(voucher.amount) + Number(voucher.fee)
                  )}`,
                  voucher.redeemed ? "Redeemed" : "Not Redeemed",
                  `${DateTime.fromISO(voucher.timestamp).toFormat(
                    "DD"
                  )} ${DateTime.fromISO(voucher.timestamp).toFormat("t")}`,
                  () => handleClick(voucher),
                ]),
              ]}
            />
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              limit={limit}
              page={page}
              total={total}
            />
          </div>
          {isOpen && (
            <div
              className={`w-5/12 md:w-4/12 bg-orange-50 rounded-lg py-10 px-8 md:fixed md:top-28 md:max-h-[81vh] md:overflow-y-auto md:right-5`}
            >
              <h4 className="mb-5">Voucher Info</h4>
              <TransactionItem
                title="Amount"
                value={`${currency("ngn")} ${moneyFormat(
                  Number(voucher.amount)
                )}`}
                isAmount
              />
              <TransactionItem
                title="Fee"
                value={`${currency("ngn")} ${moneyFormat(voucher.fee)}`}
              />
              <TransactionItem
                title="User Full Name"
                value={`${voucher.user.firstName} ${voucher.user.lastName}`}
              />
              <TransactionItem
                title="Voucher Code"
                isAmount
                value={voucher.voucher}
              />
              <TransactionItem
                title="Date & Time"
                value={`${DateTime.fromISO(voucher.timestamp).toFormat(
                  "DD"
                )} ${DateTime.fromISO(voucher.timestamp).toFormat("t")}`}
              />
              <TransactionItem
                title="Reference"
                value={voucher.paymentReference}
              />
              <TransactionItem
                title="Sender Account Number"
                value={voucher?.sender?.accountNumber}
              />
              <TransactionItem
                title="Sender Account Name"
                value={voucher?.sender?.accountName}
              />
              <TransactionItem
                title="Status"
                isStatus
                value={voucher.redeemed ? "Redeemed" : "Not Redeemed"}
                status={
                  voucher.redeemed
                    ? "text-green-500 capitalize"
                    : "text-orange-500 capitalize"
                }
              />
              {voucher.redeemed && (
                <TransactionItem
                  title="Redeemed At"
                  value={`${DateTime.fromISO(voucher.redeemedAt).toFormat(
                    "DD"
                  )} ${DateTime.fromISO(voucher.redeemedAt).toFormat("t")}`}
                />
              )}
            </div>
          )}
        </div>
      </Main>
    </Container>
  );
};

export default Vouchers;
