import { get, post } from "utilities/network";

export const httpFetchTransactions = (
  currencyType: string,
  transactionType: string,
  place?: string,
  crypto?: string,
  limit?: number,
  page?: number,
  status?: string
) => {
  return get(
    `admin/transactions/fetch?currencyType=${currencyType}&transactionType=${transactionType}&crypto=${crypto}&place=${place}&limit=${limit}&page=${page}&status=${status}`
  );
};

export const httpVendorDeposits = (type: string) => {
  return get(
    `admin/merchants/deposits?type=${type.replace("completed", "successful")}`
  );
};

export const httpVendorDeposit = (id: number) => {
  return get(`admin/merchants/deposit/${id}`);
};

export const httpFetchPlanTransactions = (id: number, crypto?: string) => {
  return get(`admin/transactions/fetchPlanTransactions/${id}?crypto=${crypto}`);
};

export const httpUSearchTransaction = (id: string) => {
  return get(`admin/transactions/search?search=${id}`);
};

export const httpFetchVouchers = (
  filter: string,
  limit?: number,
  page?: number
) => {
  return get(
    `admin/transactions/vouchers?filter=${filter}&limit=${limit}&page=${page}`
  );
};

export const httpFetchAdminMarkets = (limit?: number, page?: number) => {
  return get(`admin/transactions/fetchAdminMarket?limit=${limit}&page=${page}`);
};

export const httpSwapStatus = async ({ id }: { id: string }) => {
  return await post("admin/transactions/getSwapStatus", {
    id,
  });
};
